import axios from './axiosInstance';
import statusCodeHandler from '../actions/axiosXHRStatusHandler';

export default function({ url, method, onSuccess, onError, headers, ...conf }) {
	return axios(url, {
		method,
		headers,
		...conf,
	}).then(
		response => onSuccess(response.data, response),
		err => {
			statusCodeHandler(err);

			if (err.response) {
				if (onError) {
					onError(err.response.data, err.response.statusText);
				}
			} else if (err.request) {
				if (onError) {
					onError(err.request.data, err.request.statusText);
				}
			}
			return err;
		}
	);
}
