import actionTypes from '../constants/actionTypes';

const changeLocale = locale => dispatch => {
	dispatch({
		type: actionTypes.CHANGE_LOCALE,
		locale,
	});
};

export default changeLocale;
