import actionTypes from '../constants/actionTypes';
import { baseUrl } from '../constants/apiInfo';
import jqueryAjax from '../adapters/axiosAjax';

import authActionCreators from './authActionCreators';

const endpoints = {
	login: `${baseUrl}/auth/login`,
	userDetails: `${baseUrl}/auth/me`,
	submitEmailForPwdReset: `${baseUrl}/accounts/instructions`,
	resetPassword: `${baseUrl}/accounts/password`,
	refreshToken: `${baseUrl}/auth/refresh_token`,
	revokeToken: `${baseUrl}/auth/revoke_token`,
	prefs: `${baseUrl}/preferences/user`,
};

// get prefs
const requestUserPrefs = () => ({
	type: actionTypes.REQUEST_USER_PREFS,
});

const receivedUserPrefs = data => ({
	type: actionTypes.RECEIVED_USER_PREFS,
	data,
});

const failedToReceiveUserPrefs = error => dispatch => {
	dispatch(authActionCreators.clearUser()); // why?
	return {
		type: actionTypes.FAILED_USER_PREFS,
		error,
	};
};

export const sendRequestToGetUserPrefs = () => dispatch => {
	dispatch(
		jqueryAjax({
			requestAction: requestUserPrefs,
			successAction: receivedUserPrefs,
			failureAction: failedToReceiveUserPrefs,
			url: endpoints.prefs,
			method: 'GET',
		})
	).then(prefs => {
		prefs.forEach(pref => {
			if (pref.key.indexOf('lastViewed') !== -1) {
				localStorage.setItem(pref.key, pref.value);
			}
		});
		localStorage.setItem('userPrefs', JSON.stringify(prefs));
	});
};

// set pref
const startSetPref = () => ({
	type: actionTypes.START_PREF_UPDATE,
});

const prefSetted = data => ({
	type: actionTypes.PREF_UPDATED,
	data,
});

const failedToSetPref = error => ({
	type: actionTypes.FAILED_PREF_UPDATE,
	error,
});

export const setPref = (key, value) => {
	if (key.indexOf('lastViewed') !== -1) {
		localStorage.setItem(key, value);
	}
	return jqueryAjax({
		requestAction: startSetPref,
		successAction: prefSetted,
		failureAction: failedToSetPref,
		url: endpoints.prefs,
		method: 'PUT',
		params: { key, value },
		json: true,
	});
};
