import axiosAdapter from './axiosAdapter';
import store from '../store';
import authActionCreators from '../actions/authActionCreators';
import menuControlActionCreators from '../actions/menuControlActionCreators';

/* eslint consistent-return:0 */
const refreshTokenRef = {
	call: null,
	inProgress: false,
};
const regularAjaxRef = {
	call: null,
	inProgress: false,
};

// api adapter
const XHRAdapter = (
	requestAction,
	successAction,
	failureAction,
	method,
	url,
	params,
	json,
	auth,
	override,
	getData,
	disableAirlineSwitcher,
	dispatch,
	getCompleteResponse,
	returnPromiseReject,
	cancelToken
) =>
	new Promise((resolve, reject) => {
		if (requestAction) {
			dispatch(requestAction(params));
		}
		if (disableAirlineSwitcher) {
			store.dispatch(menuControlActionCreators.disableAirlineSwitcher(true));
		}
		const headers = {};

		if (auth) {
			try {
				headers['X-Auth-Token'] = localStorage.getItem('token');
			} catch (err) {
				store.dispatch(authActionCreators.userFailedLogin(err));
			}
		}

		const httpMethod = method ? method.toUpperCase() : 'GET';

		let conf = {
			url,
			method: httpMethod,
			headers,
		};

		if (cancelToken) {
			conf.cancelToken = cancelToken;
		}

		if (httpMethod === 'GET') {
			conf.params = params;
		}

		if (httpMethod === 'POST' && !json && typeof params !== 'string') {
			if (params && params.constructor.name === 'FormData') {
				conf.data = params;
			} else if (params) {
				const formData = new URLSearchParams();
				Object.entries(params).forEach(item => formData.append(item[0], item[1]));
				conf.data = formData;
			}
		} else {
			const tempParams = typeof params === 'string' ? JSON.parse(params) : params;
			conf.data = tempParams;
		}

		conf.onSuccess = (data, response) => {
			if (disableAirlineSwitcher) {
				store.dispatch(menuControlActionCreators.disableAirlineSwitcher(false));
			}
			if (successAction) {
				dispatch(successAction(getData ? getData(data, params) : data, response));
			}

			if (getCompleteResponse) {
				return resolve(response);
			}
			return resolve(data);
		};

		conf.onError = (errorData, statusText) => {
			if (disableAirlineSwitcher) {
				store.dispatch(menuControlActionCreators.disableAirlineSwitcher(false));
			}
			const action = failureAction || successAction;
			if (action) {
				dispatch(action(errorData, statusText));
			}
			if (returnPromiseReject) {
				return reject(errorData, statusText);
			}
		};
		if (override) {
			conf = { ...conf, ...override };
		}

		const refreshTokenRequest = () => {
			refreshTokenRef.inProgress = true;
			refreshTokenRef.call = axiosAdapter(conf).then(() => {
				refreshTokenRef.inProgress = false;
			});
		};

		const regularAjaxRequest = () => {
			regularAjaxRef.inProgress = true;
			regularAjaxRef.call = axiosAdapter(conf).then(() => {
				regularAjaxRef.inProgress = false;
			});
		};

		const action = successAction || failureAction;

		if (action && action === authActionCreators.tokenRefreshed) {
			// if the action is tokenRefresh, that means that we have to place an ajax call
			// to refresh the token, but first we check if there is an api call in progress and
			// if it is, we wait for it to finish and then() we place a tokenRefresh call.
			if (regularAjaxRef.inProgress) {
				regularAjaxRef.call.then(() => {
					refreshTokenRequest();
				});
			} else {
				refreshTokenRequest();
			}
		} else if (refreshTokenRef.inProgress) {
			// If an regular (non-refresh-token) api call is triggered and refresh token is in process, we have to wait
			// for refresh to get back and then to kick off the api call. Also we have to update the x-auth-token with
			// newly obtained value
			refreshTokenRef.call.then(() => {
				store.dispatch(authActionCreators.setKickTimer());
				conf.headers['X-Auth-Token'] = localStorage.getItem('token');
				regularAjaxRequest();
			});
		} else {
			// Refresh token is not in process, proceed with the call - this will mostly be the case.
			store.dispatch(authActionCreators.setKickTimer());
			return regularAjaxRequest();
		}
	});

// url: url to send request to
// method: request method
// params: request params(body), object
// json: whether data should be send as json, if true it's stringified and correct headers are sent, default false
// auth: whether authentication token is needed, if true auth token header is added, default true
// override: object with which to override options of the underlying xHR or fetch or whatever function

//
// redux-thunk adapter
export default function jqueryAjax({
	requestAction,
	successAction,
	failureAction,
	method,
	url,
	params = null,
	json = false,
	auth = true,
	override = null,
	getData,
	disableAirlineSwitcher = false,
	getCompleteResponse = false,
	returnPromiseReject = false,
	cancelToken,
}) {
	return dispatch =>
		XHRAdapter(
			requestAction,
			successAction,
			failureAction,
			method,
			url,
			params,
			json,
			auth,
			override,
			getData,
			disableAirlineSwitcher,
			dispatch,
			getCompleteResponse,
			returnPromiseReject,
			cancelToken
		);
}
