const defaultInitialState = {
	data: null,
	isLoading: false,
};

export default function(actionHandlers, initialState = { ...defaultInitialState }) {
	return (state = initialState, action) => {
		if (!actionHandlers[action.type]) {
			return state;
		}

		return actionHandlers[action.type](state, action);
	};
}
