export default function() {
	const UI_URL_PROD_HTTP = 'http://partner.ca.intelsat.com';
	const UI_URL_STAGE_HTTP = 'http://partner-stage.ca.intelsat.com';

	const UI_URL_PROD_HTTPS = 'https://partner.ca.intelsat.com';
	const UI_URL_STAGE_HTTPS = 'https://partner-stage.ca.intelsat.com';

	const UI_URL_PROD_HTTP_GOGO = 'http://partner.gogoair.com';
    const UI_URL_STAGE_HTTP_GOGO = 'http://partner-stage.gogoair.com';

    const UI_URL_PROD_HTTPS_GOGO = 'https://partner.gogoair.com';
    const UI_URL_STAGE_HTTPS_GOGO = 'https://partner-stage.gogoair.com';

	const currentUrl = window.location.href;

	let protocol = 'https';
	let env = 'dev';
	let envOverride;

	if (currentUrl.indexOf('localhost') > -1 || currentUrl.indexOf('10.0') > -1) {
		// eslint-disable-next-line
		if (typeof LOCAL_ENV !== 'undefined') {
			// eslint-disable-next-line
			envOverride = LOCAL_ENV;
		} else {
			envOverride = null;
		}
	}

	if (envOverride) {
		env = envOverride;
	} else if (currentUrl.indexOf('http://') > -1) {
		protocol = 'http';
		if (currentUrl.indexOf(UI_URL_PROD_HTTP) > -1 || currentUrl.indexOf(UI_URL_PROD_HTTP_GOGO) > -1) {
			env = 'prod';
		} else if (currentUrl.indexOf(UI_URL_STAGE_HTTP) > -1 || currentUrl.indexOf(UI_URL_STAGE_HTTP_GOGO) > -1) {
			env = 'stage';
		} else {
			env = 'dev';
		}
	} else if (currentUrl.indexOf('https://') > -1) {
		protocol = 'https';
		if (currentUrl.indexOf(UI_URL_PROD_HTTPS) > -1 || currentUrl.indexOf(UI_URL_PROD_HTTPS_GOGO) > -1) {
			env = 'prod';
		} else if (currentUrl.indexOf(UI_URL_STAGE_HTTPS) > -1 || currentUrl.indexOf(UI_URL_STAGE_HTTPS_GOGO) > -1) {
			env = 'stage';
		} else {
			env = 'dev';
		}
	}

	// Hardcoded partnerId (Startek partner) until we have API support for pulling data based on
	// Startek roles
	// ID on dev is 763 and ID on stage in 753
	// not used!!!!
	if (env === 'dev') {
		window.startekID = 763;
	} else if (env === 'stage') {
		window.startekID = 753;
	}

	window.env = env;
	window.protocol = protocol;
}
