import environmentManager from './environmentManager';
import authUrls from './apiUrls';
import tblUrls from './tblUrls';
import tblReportApiUrls from './tblReportApiUrls';
import expUrls from './exportUrls';
import adminAPI from './adminUrls';

//Set env and protocol
environmentManager();

const { protocol } = window;
const { env } = window;

export const baseUrl = authUrls[protocol][env];

export const tblBaseUrl = tblUrls[protocol][env];
export const adminUrl = adminAPI[protocol][env];
export const tblReportBaseUrl = tblReportApiUrls[protocol][env];

export const exportUrls = {
	excel: expUrls.excel[protocol][env],
	pdf: expUrls.pdf[protocol][env],
	chart: expUrls.chart[protocol][env],
};

export default {
	protocol,
	env,
	baseUrl,
};
