import { error } from 'react-notification-system-redux';
import { bugsnag } from 'gogo-sphere-shared-util';

import actionTypes from '../constants/actionTypes';
import store from '../store';

export default err => {
	if (err.response && err.response.config && err.response.config.url.endsWith('health')) {
		return;
	}
	const status =
		err.response && err.response.status ? err.response.status : err.data && err.data.status ? err.data.status : 0;
	switch (status) {
		case 401:
			store.dispatch({
				type: actionTypes.UNAUTHORIZED_ERROR,
				data: {},
			});
			window.location = '/#/login';
			break;

		case 498:
			store.dispatch({
				type: actionTypes.TOKEN_EXPIRED,
				data: {},
			});
			break;
		case 403:
			const user = JSON.parse(localStorage.getItem('current_user'));

			bugsnag.notify('User received a 403 error', {
				metaData: {
					debug: {
						permissions: user.permissions,
						location: window.location.href,
					},
				},
				severity: 'warning',
				context: '403 occurred',
			});

			window.location.href = '/#/forbidden';
			break;
		case 500:
			store.dispatch(
				error({
					message: 'Internal Server Error',
				})
			);
			break;
		case 0:
			store.dispatch(
				error({
					message: 'Network Error',
				})
			);
			break;
		default:
	}
};
