import actionTypes from '../constants/actionTypes';

const initialState = {
	isSendingEmail: false,
	sendingEmailDone: false,
	isPasswordResetting: false,
	passwordResetDone: false,
	isPasswordChanging: false,
	passwordChangeDone: false,
	errorMessage: null,
};

export default function passwordStatus(state = initialState, action) {
	switch (action.type) {
		case actionTypes.REQUEST_PASSWORD_CHANGE:
			return {
				...state,
				isPasswordChanging: true,
				passwordChangeDone: false,
				errorMessage: null,
			};
		case actionTypes.PASSWORD_CHANGED:
			return {
				...state,
				isPasswordChanging: false,
				passwordChangeDone: true,
				errorMessage: null,
			};
		case actionTypes.FAILED_PASSWORD_CHANGE:
			return {
				...state,
				isPasswordChanging: false,
				passwordChangeDone: false,
				errorMessage: action.error,
			};
		case actionTypes.START_SUBMIT_EMAIL_FOR_PASSWORD_RESET:
			return {
				...state,
				isSendingEmail: true,
				sendingEmailDone: false,
				errorMessage: null,
			};
		case actionTypes.SUBMITTED_EMAIL_FOR_PASSWORD_RESET:
			return {
				...state,
				isSendingEmail: false,
				sendingEmailDone: true,
				errorMessage: null,
			};
		case actionTypes.FAILED_SUBMIT_EMAIL_FOR_PASSWORD_RESET:
			return {
				...state,
				isSendingEmail: false,
				errorMessage: action.error,
				sendingEmailDone: false,
			};
		case actionTypes.REQUEST_PASSWORD_RESET:
			return {
				...state,
				isPasswordResetting: true,
				errorMessage: null,
				passwordResetDone: false,
			};
		case actionTypes.PASSWORD_RESETTED:
			return {
				...state,
				isPasswordResetting: false,
				errorMessage: null,
				passwordResetDone: true,
			};
		case actionTypes.FAILED_PASSWORD_RESET:
			return {
				...state,
				isPasswordResetting: false,
				errorMessage: action.error,
				passwordResetDone: false,
			};
		case actionTypes.RESET_PASSWORD_STATUS:
			return initialState;
		default:
			return state;
	}
}
