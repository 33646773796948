import actionTypes from './actionTypes';
import { setPref } from '../actions/userPrefsActioncreators';

export function setTimezone(data) {
	return {
		type: actionTypes.SET_TIME_ZONE,
		data,
	};
}

export function setAndSaveTimezone(data, savePrefKey) {
	return dispatch => {
		dispatch(setPref(savePrefKey, data)).then(() => {
			dispatch(setTimezone(data));
		});
	};
}
