import actionTypes from '../constants/actionTypes';

const initialState = {
	inProgress: false,
	error: null,
};

export default function emailService(state = initialState, action) {
	switch (action.type) {
		case actionTypes.REQUEST_EMAIL_SERVICE:
			return {
				...state,
				inProgress: true,
				error: initialState.error,
			};
		case actionTypes.ERROR_EMAIL_SERVICE:
			return {
				...state,
				inProgress: false,
				error: initialState.error,
			};
		case actionTypes.SUCCESS_EMAIL_SERVICE:
			return {
				...state,
				inProgress: false,
				error: action.error && action.error.message ? action.error.message : action.error,
			};
		case actionTypes.RESET_EMAIL_SERVICE:
			return {
				...initialState,
			};
		default:
			return state;
	}
}
