import React from 'react';
import { connect } from 'react-redux';
import store from './store';

export default function connectWithSharedRedux(Component, ...args) {
	const ConnectedComponent = connect(...args)(Component);
	return props => <ConnectedComponent {...props} store={store} />;
}

/* e.g. in component you will connect it to 'shared-redux' like this:

	const WrappedComponent = connectWithSharedRedux(ComponentYouWantToConnect, mapStateToProps, mapDispatchToProps);

	function mapStateToProps (state) {
		return {
			whatEverProp: state.whatEverYouWantFromSharedReduxStore
		}
	}

	// if you need to dispatch actions
	function mapDispatchToProps (dispatch) {
		return {
			whatEverAction: dispatch(someActionThatShouldUpdateSharedStore)
		};
	}

	export default WrappedComponent;
*/
