import actionTypes from '../constants/actionTypes';

export default function loginErrorMessage(state = null, action) {
	switch (action.type) {
		case actionTypes.USER_FAILED_LOGIN:
			return action.error ? action.error.message || 'Unknown error' : null;
		case actionTypes.LOCAL_STORAGE_UNAVAILABLE:
			return 'Local storage unavailable. Please enable it and try again.';
		case actionTypes.RECEIVED_USER_DETAILS:
			return null;
		case actionTypes.FAILED_USER_DETAILS:
			return action.error ? action.error.message || 'Unknown error' : null;
		case actionTypes.CLEAR_LOGIN_ERROR:
			return null;
		default:
			return state;
	}
}
