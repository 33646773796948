import actionTypes from '../constants/actionTypes';

let expires;

try {
	expires = localStorage.getItem('expires');
} catch (err) {
	expires = 0;
}

const initialState = parseInt(expires) < Date.now();

export default function isTokenExpired(state = initialState, action) {
	switch (action.type) {
		case actionTypes.TOKEN_REVOKED:
			return true;
		case actionTypes.USER_LOGGED_IN:
			return false;
		default:
			return state;
	}
}
