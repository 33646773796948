import ActionTypes from '../constants/actionTypes';
import axiosAjax from '../adapters/axiosAjax';
import { tblBaseUrl as baseUrl } from '../constants/apiInfo';

const getInternalAppsRequest = () => ({
	type: ActionTypes.REQUEST_TABLEAU_INTERNAL_APPS,
});

const getInternalAppsSuccess = data => ({
	type: ActionTypes.TABLEAU_INTERNAL_APPS_SUCCESS,
	data,
});

const getInternalAppsError = error => ({
	type: ActionTypes.TABLEAU_INTERNAL_APPS_ERROR,
	error,
});

export default () =>
	axiosAjax({
		params: { internal: true },
		url: `${baseUrl}/applications`,
		method: 'GET',
		json: true,
		requestAction: getInternalAppsRequest,
		successAction: getInternalAppsSuccess,
		failureAction: getInternalAppsError,
	});
