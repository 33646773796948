export default {
	USER: 'ROLE_USER',
	PARTNER_ADMIN: 'ROLE_PARTNER_ADMIN',
	GOGO_USER: 'ROLE_GOGO_USER',
	GOGO_ADMIN: 'ROLE_GOGO_ADMIN',
	GOGO_SUPER_ADMIN: 'ROLE_GOGO_SUPER_ADMIN',
	CMP_USER: 'ROLE_CMP_USER',
	CMP_AGENT: 'ROLE_CMP_AGENT',
	CMP_LEAD: 'ROLE_CMP_LEAD',
	CMP_SUPERVISOR: 'ROLE_CMP_SUPERVISOR',
	CMP_ADMIN: 'ROLE_CMP_ADMIN',
	CREW_USER: 'ROLE_CREW_USER'
};
