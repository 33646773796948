import { createCrudReducers } from 'redux-thunk-crud';

import { tableauAppsActionCreators, tableauReportsActionCreators } from '../actions/tableauAppsActionCreators';
import actionTypes from '../constants/actionTypes';

let storageTableauApps;

try {
	storageTableauApps = JSON.parse(localStorage.getItem('tableauApps')) || [];
} catch (err) {
	storageTableauApps = null;
}

export const tableauApps = createCrudReducers(
	tableauAppsActionCreators,
	'CRUD',
	'RESET_ALL_DATA',
	false,
	storageTableauApps
);
export const tableauReports = createCrudReducers(tableauReportsActionCreators);

export const gotTableauApps = (state = false, action) => {
	switch (action.type) {
		case 'GOT_TABLEAU_APPS':
			return true;
		default:
			return state;
	}
};

let defaultBIAId;

try {
	defaultBIAId = localStorage.getItem('BIATableauAppId');
} catch (e) {
	defaultBIAId = null;
}

export const BIATableauAppId = (state = defaultBIAId || null, action) => {
	switch (action.type) {
		case actionTypes.RECEIVED_BIA_ID:
			return action.id;
		default:
			return state;
	}
};
