const { env } = window;
// eslint-disable-next-line
let MicrosoftOnlineSSOUrl;

if (env === 'dev') {
	MicrosoftOnlineSSOUrl =
		'https://myapps.microsoft.com/signin/Intelsat%20Sphere%20-%20Dev/944401d5-ffc6-4fbf-9ef8-aacbf367a428';
} else if (env === 'stage') {
	MicrosoftOnlineSSOUrl =
		'https://myapps.microsoft.com/signin/Intelsat%20Sphere%20-%20Stage/05ea0ac4-f338-4ccf-8aaf-8c0e414bb909';
} else if (env === 'prod') {
	MicrosoftOnlineSSOUrl =
		'https://myapps.microsoft.com/signin/Intelsat%20Sphere/42082d42-869e-4129-8006-c1b1d34956fa';
}

export default MicrosoftOnlineSSOUrl;
