import actionTypes from '../constants/actionTypes';

const initialState = {};

export default function notifications(state = initialState, action) {
	switch (action.type) {
		case actionTypes.RECEIVED_AIRLINE_DETAILS:
			return {};
		default:
			return state;
	}
}
