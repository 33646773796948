import apiUrls from './apiUrls';

const MAIL_URL_SUFFIX = '/mail';

export default {
	http: {
		prod: apiUrls.http.prod + MAIL_URL_SUFFIX,
		stage: apiUrls.http.stage + MAIL_URL_SUFFIX,
		dev: apiUrls.http.dev + MAIL_URL_SUFFIX,
	},
	https: {
		prod: apiUrls.https.prod + MAIL_URL_SUFFIX,
		stage: apiUrls.https.stage + MAIL_URL_SUFFIX,
		dev: apiUrls.https.dev + MAIL_URL_SUFFIX,
	},
};

export const GGSSupportEmails = {
	http: {
		prod: 'gssupport@intelsat.com',
		stage: 'gssupport@intelsat.com',
		dev: 'gssupport@intelsat.com',
	},
	https: {
		prod: 'gssupport@intelsat.com',
		stage: 'gssupport@intelsat.com',
		dev: 'gssupport@intelsat.com',
	},
};
