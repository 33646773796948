import actionTypes from '../constants/actionTypes';

let initialState;

try {
	initialState = {
		selectedPartner: localStorage.getItem('partnerCode'),
		partnerDetails: JSON.parse(localStorage.getItem('partnerDetails')),
		isLoading: false,
	};
} catch (error) {
	initialState = {};
}

export default function currentPartner(state = initialState, action) {
	switch (action.type) {
		case actionTypes.REQUEST_PARTNER_DETAILS:
			return {
				...state,
				isLoading: true,
			};
		case actionTypes.RECEIVED_PARTNER_DETAILS:
			return {
				selectedPartner: action.data['partner_code'],
				partnerDetails: action.data,
				isLoading: false,
			};
		case actionTypes.FAILED_PARTNER_DETAILS:
			return {
				...state,
				isLoading: false,
			};
		case actionTypes.CLEAR_PARTNERS:
			return {
				selectedPartner: null,
				partnerDetails: {},
				isLoading: false,
			};
		default:
			return state;
	}
}
