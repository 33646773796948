import ActionTypes from '../constants/actionTypes';

const initialState = {
	error: false,
	isLoading: false,
	data: [],
};

export default (state = initialState, action) => {
	switch (action.type) {
		case ActionTypes.REQUEST_TABLEAU_INTERNAL_APPS:
			return {
				...state,
				isLoading: true,
			};
		case ActionTypes.TABLEAU_INTERNAL_APPS_SUCCESS:
			return {
				...state,
				error: false,
				data: (action.data._embedded && action.data._embedded.applications) || [],
			};
		case ActionTypes.TABLEAU_INTERNAL_APPS_ERROR:
			return {
				...state,
				isLoading: true,
			};
		default:
			return state;
	}
};
