import actionTypes from '../constants/actionTypes';

const menuControlActionCreators = {
	closeAll() {
		return {
			type: actionTypes.CLOSE_ALL_MENUS,
		};
	},

	toggleSidebar(isSidebarOpen) {
		return {
			type: actionTypes.TOGGLE_SIDEBAR,
			isSidebarOpen,
		};
	},

	toggleAppMenu(isAppMenuOpen) {
		return {
			type: actionTypes.TOGGLE_APP_MENU,
			isAppMenuOpen,
		};
	},

	toggleProfileMenu(isProfileMenuOpen) {
		return {
			type: actionTypes.TOGGLE_PROFILE_MENU,
			isProfileMenuOpen,
		};
	},

	disableAirlineSwitcher(isAirlineSwitcherDisabled) {
		return {
			type: actionTypes.DISABLE_AIRLINE_SWITCHER,
			isAirlineSwitcherDisabled,
		};
	},
};

export default menuControlActionCreators;
