import actionTypes from '../constants/actionTypes';

let storedToken;
let isTokenExpired;

try {
	storedToken = localStorage.getItem('token');
	isTokenExpired = parseInt(localStorage.getItem('expires')) < Date.now();
} catch (e) {
	storedToken = null;
}

const initialState = storedToken && !isTokenExpired ? 'LOGGED_IN' : 'NOT_LOGGING_IN';

export default function loginStatus(state = initialState, action) {
	switch (action.type) {
		case actionTypes.USER_LOGGING_IN:
			return 'LOGGING_IN';
		case actionTypes.RECEIVED_USER_DETAILS:
			return 'LOGGED_IN';
		case actionTypes.USER_FAILED_LOGIN:
			return 'FAILED';
		case actionTypes.LOCAL_STORAGE_UNAVAILABLE:
			return 'FAILED';
		case actionTypes.USER_LOGGED_IN:
			return 'FETCHING_USER_DETAILS';
		case actionTypes.CLEAR_USER:
			return 'NOT_LOGGING_IN';
		default:
			return state;
	}
}
