import { CrudActionCreators } from 'redux-thunk-crud';

import actionTypes from '../constants/actionTypes';
import axiosAjax from '../adapters/axiosAjax';
import { tblBaseUrl as baseUrl } from '../constants/apiInfo';

class TableauAppsActionCreator extends CrudActionCreators {
	constructor(url, actionTypesSuffix, settings = {}) {
		super(url, actionTypesSuffix, settings);

		this.url = url;
		this.baseUrl = url;
	}

	getApp(id) {
		this.url = this.baseUrl;

		return super.fetchOne(id);
	}

	getApps(partner) {
		return dispatch => {
			if (partner) {
				this.url = `${this.baseUrl}?partner=${partner}`;
				dispatch({ type: 'GOT_TABLEAU_APPS' });
			} else {
				this.url = this.baseUrl;
			}

			dispatch(super.fetchList({ size: 1000 })).then(resp => {
				if (partner && resp._embedded) {
					localStorage.setItem('tableauApps', JSON.stringify(resp));
				}
			});
		};
	}

	createApp(data) {
		this.url = this.baseUrl;
		return dispatch => {
			dispatch(super.save(JSON.stringify(data), undefined, 'POST')).then(() => {
				dispatch(this.getApps());
			});
		};
	}

	updateApp(data, id) {
		this.url = `${this.baseUrl}/${id}`;

		return dispatch => {
			dispatch(super.save(JSON.stringify(data), undefined, 'PUT')).then(() => {
				dispatch(this.getApps());
			});
		};
	}

	deleteApp(id) {
		this.url = this.baseUrl;
		return dispatch => {
			dispatch(super.delete(id)).then(() => {
				dispatch(this.getApps());
			});
		};
	}
}

export const tableauAppsActionCreators = new TableauAppsActionCreator(`${baseUrl}/applications`, 'TABLEAU_APP', {
	adapter: axiosAjax,
	additionalAdapterSettings: {
		override: {
			contentType: 'application/json',
		},
	},
});

class ReportsActionCreators extends CrudActionCreators {
	constructor(url, actionTypesSuffix, settings = {}) {
		super(url, actionTypesSuffix, settings);

		this.url = url;
		this.defaultUrl = url;
	}

	getReports(partner, appId) {
		const params = {
			size: 1000,
			trusted: true,
			application: appId,
		};

		if (!partner) {
			this.url = `${baseUrl}/reports`;
		} else {
			this.url = `${baseUrl}/partners/${partner}/reports`;
		}

		return this.fetchList(params);
	}

	createReport(tableauReport) {
		this.url = this.defaultUrl;
		return dispatch => {
			dispatch(super.save(tableauReport, undefined, 'POST')).then(() => {
				dispatch(tableauAppsActionCreators.getApp(tableauReport.tableau_application_id));
			});
		};
	}

	updateReport(tableauReport, id) {
		this.url = this.defaultUrl;
		return dispatch => {
			dispatch(super.save(tableauReport, id, 'PUT')).then(() => {
				dispatch(tableauAppsActionCreators.getApp(tableauReport.tableau_application_id));
			});
		};
	}

	deleteReport(id, appId) {
		this.url = this.defaultUrl;
		return dispatch => {
			dispatch(super.delete(id)).then(() => {
				dispatch(tableauAppsActionCreators.getApp(appId));
			});
		};
	}

	getReportToken(partner, reportName, callback) {
		this.url = `${baseUrl}/partners/${partner}/reports/${reportName}`;

		const params = {
			trusted: true,
		};

		if (callback) {
			callback();
		}
		return super.fetchList(params);
	}
}

export const tableauReportsActionCreators = new ReportsActionCreators(`${baseUrl}/reports`, 'TABLEAU_REPORT', {
	adapter: axiosAjax,
	json: true,
});

export const resetAllData = () => ({
	type: actionTypes.RESET_ALL_DATA,
});

const receivedBiaId = id => ({
	type: actionTypes.RECEIVED_BIA_ID,
	id,
});

const failedBiaId = error => ({
	type: actionTypes.FAILED_BIA_ID,
	error,
});

export const getBIATableauAppId = () => dispatch => {
	dispatch(
		axiosAjax({
			successAction: receivedBiaId,
			failureAction: failedBiaId,
			url: `${baseUrl}/applications/bia`,
			override: {
				contentType: 'application/json',
			},
		})
	).then(resp => {
		localStorage.setItem('BIATableauAppId', resp);
	});
};
