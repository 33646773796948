import en from './en.json';
import en_US from './en-US.json';
import sr_RS from './sr-RS.json';

const locales = {};

locales['en'] = en;
locales['en-US'] = en_US;
locales['sr-RS'] = sr_RS;

export default locales;
