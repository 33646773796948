import actionTypes from '../constants/actionTypes';
import messages from '../locales/messages';

// On Safari, we have en-us instead of en-US, so we have to capitalize it
const capitalizeRegion = locale => {
	let newLocale = locale.split('-');

	if (newLocale[1]) {
		newLocale[1] = newLocale[1].toUpperCase();
	}

	newLocale = newLocale.join('-');
	return newLocale;
};

let defaultLocale = 'en-US';
defaultLocale = capitalizeRegion(defaultLocale);
const defaultMessages = messages[defaultLocale];

export default function language(
	state = {
		locale: defaultLocale,
		messages: defaultMessages,
	},
	action
) {
	switch (action.type) {
		case actionTypes.CHANGE_LOCALE:
			const locale = capitalizeRegion(action.locale);
			return {
				locale,
				messages: messages[locale] || defaultMessages,
			};
		default:
			return state;
	}
}
