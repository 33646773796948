import axiosAjax from '../adapters/axiosAjax';

import actionTypes from '../constants/actionTypes';
import { adminUrl } from '../constants/apiInfo';

const requestPartners = params => ({
	type: actionTypes.REQUEST_PARTNERS,
	params,
});

const receivedPartners = data => ({
	type: actionTypes.RECEIVED_PARTNERS,
	data: data.content,
});

const receivedPartnersError = () => ({
	type: actionTypes.RECEIVED_PARTNERS_ERROR,
});

const requestUpdatePartner = () => ({
	type: actionTypes.REQUEST_UPDATE_PARTNER,
});

const updatePartnerSuccess = partnerUpdated => ({
	type: actionTypes.UPDATE_PARTNER_SUCCESS,
	partnerUpdated,
});

const updatePartnerError = () => ({
	type: actionTypes.UPDATE_PARTNER_ERROR,
});

const requestCreatePartner = () => ({
	type: actionTypes.REQUEST_CREATE_PARTNER,
});

const createPartnerSuccess = partnerCreated => ({
	type: actionTypes.CREATE_PARTNER_SUCCESS,
	partnerCreated,
});

const createPartnerError = () => ({
	type: actionTypes.CREATE_PARTNER_ERROR,
});

export const fetchPartners = (params = { size: 1000 }) =>
	axiosAjax({
		params,
		url: `${adminUrl}/partners`,
		method: 'GET',
		requestAction: requestPartners,
		successAction: receivedPartners,
		failureAction: receivedPartnersError,
	});

export const createPartner = params =>
	axiosAjax({
		params,
		url: `${adminUrl}/partners`,
		method: 'POST',
		json: true,
		requestAction: requestCreatePartner,
		successAction: createPartnerSuccess,
		failureAction: createPartnerError,
	});

export const updatePartner = params =>
	axiosAjax({
		params,
		url: `${adminUrl}/partners/${params.partner_id}`,
		method: 'PUT',
		json: true,
		requestAction: requestUpdatePartner,
		successAction: updatePartnerSuccess,
		failureAction: updatePartnerError,
	});

export const setCurrentPartner = partner_id => ({
	type: actionTypes.SET_CURRENT_PARTNER,
	partner_id,
});

export const updatePartnerDomains = (params, id) =>
	axiosAjax({
		params,
		url: `${adminUrl}/partners/${id}`,
		method: 'PATCH',
		json: true,
		requestAction: requestUpdatePartner,
		successAction: updatePartnerSuccess,
		failureAction: updatePartnerError,
	});
