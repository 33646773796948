import actionTypes from '../constants/actionTypes';

const initialState = {
	isSidebarOpen: false,
	isAppMenuOpen: false,
	isProfileMenuOpen: false,
	isAirlineSwitcherDisabled: false,
};

export default function menuControl(state = initialState, action) {
	switch (action.type) {
		case actionTypes.CLOSE_ALL_MENUS:
			return {
				...state,
				isSidebarOpen: false,
				isAppMenuOpen: false,
				isProfileMenuOpen: false,
			};
		case actionTypes.TOGGLE_SIDEBAR:
			return {
				...state,
				isSidebarOpen: action.isSidebarOpen,
			};
		case actionTypes.TOGGLE_APP_MENU:
			return {
				...state,
				isAppMenuOpen: action.isAppMenuOpen,
			};
		case actionTypes.TOGGLE_PROFILE_MENU:
			return {
				...state,
				isProfileMenuOpen: action.isProfileMenuOpen,
			};
		case actionTypes.DISABLE_AIRLINE_SWITCHER:
			return {
				...state,
				isAirlineSwitcherDisabled: action.isAirlineSwitcherDisabled,
			};
		default:
			return state;
	}
}
