import actionTypes from '../constants/actionTypes';

export default function forcedFromPage(state = '/', action) {
	switch (action.type) {
		case actionTypes.SAVE_FORCED_FROM_PAGE:
			return action.pathname !== '/logout' ? action.pathname : '/';
		case actionTypes.TOKEN_REVOKED:
			return '/';
		default:
			return state;
	}
}
