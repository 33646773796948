import actionTypes from '../constants/actionTypes';

const initialState = {
	partners: [],
	partnersLoading: false,
	currentPartner: null,
};

export default function(state = initialState, action) {
	switch (action.type) {
		case actionTypes.REQUEST_PARTNERS:
		case actionTypes.REQUEST_CREATE_PARTNER:
		case actionTypes.REQUEST_UPDATE_PARTNER:
			return {
				...state,
				partnersLoading: true,
			};
		case actionTypes.RECEIVED_PARTNERS:
			return {
				...state,
				partnersLoading: false,
				partners: action.data,
			};
		case actionTypes.CREATE_PARTNER_SUCCESS:
			return {
				...state,
				partnersLoading: false,
				partners: [action.partnerCreated, ...state.partners],
			};
		case actionTypes.UPDATE_PARTNER_SUCCESS:
			const { partner_id } = action.partnerUpdated;
			const index = state.partners.findIndex(partner => partner.partner_id === partner_id);

			return {
				...state,
				partnersLoading: false,
				currentPartner: {
					...state.currentPartner,
					...action.partnerUpdated,
				},
				partners: [
					...state.partners.slice(0, index),
					action.partnerUpdated,
					...state.partners.slice(index + 1),
				],
			};
		case actionTypes.RECEIVED_PARTNERS_ERROR:
		case actionTypes.CREATE_PARTNER_ERROR:
		case actionTypes.UPDATE_PARTNER_ERROR:
			return {
				...state,
				partnersLoading: false,
			};
		case actionTypes.SET_CURRENT_PARTNER:
			const partner = state.partners.find(p => p.partner_id === action.partner_id);

			return {
				...state,
				currentPartner: partner,
			};
		default:
			return state;
	}
}
