/* eslint-disable no-empty */
import { bugsnag } from 'gogo-sphere-shared-util';
import actionTypes from '../constants/actionTypes';
import { baseUrl } from '../constants/apiInfo';
import axiosAjax from '../adapters/axiosAjax';
import './axiosXHRStatusHandler';
import { fetchPartners } from './partnerActionCreators';

const endpoints = {
	login: `${baseUrl}/auth/login`,
	userDetails: `${baseUrl}/auth/me`,
	refreshToken: `${baseUrl}/auth/refresh_token`,
	revokeToken: `${baseUrl}/auth/revoke_token`,
	prefs: `${baseUrl}/preferences/user`,
	sso: `${baseUrl}/saml`,
};

let kickTimer = null;
let refreshTokenTimer = null;

const kickInterval = 1000 * 60 * 60;
const tokenRefreshIntervalMS = 1000 * 60 * 3;

class AuthActionCreators {
	// login
	userStartedLogin = () => ({
		type: actionTypes.USER_LOGGING_IN,
	});

	userLoggedIn = data => dispatch => {
		dispatch(this.setUserToken(data));
		dispatch(this.triggerRefreshTokenTimer());

		dispatch({
			type: actionTypes.USER_LOGGED_IN,
			data,
		});
	};

	userFailedLogin = error => dispatch => {
		dispatch(this.clearUser());

		dispatch({
			type: actionTypes.USER_FAILED_LOGIN,
			error,
		});
	};

	sendCredentials = (email, password) =>
		axiosAjax({
			requestAction: this.userStartedLogin,
			successAction: this.userLoggedIn,
			failureAction: this.userFailedLogin,
			url: endpoints.login,
			method: 'POST',
			auth: false,
			params: { email, password },
		});

	// user details
	requestDetails = () => ({
		type: actionTypes.REQUEST_USER_DETAILS,
	});

	receivedDetails = data => dispatch => {
		try {
			localStorage.setItem('current_user', JSON.stringify(data));
		} catch (e) {
			return dispatch(this.localStorageUnavailable());
		}

		// return {
		// 	type: actionTypes.RECEIVED_USER_DETAILS,
		// 	data,
		// };
		return dispatch({
			type: actionTypes.RECEIVED_USER_DETAILS,
			data,
		});
	};

	failedToReceiveDetails = error => dispatch => {
		dispatch(this.clearUser());
		dispatch({
			type: actionTypes.FAILED_USER_DETAILS,
			error,
		});
	};

	sendRequestToGetCurrentUserDetails = () => dispatch =>
		dispatch(
			axiosAjax({
				requestAction: this.requestDetails,
				successAction: this.receivedDetails,
				failureAction: this.failedToReceiveDetails,
				url: endpoints.userDetails,
				method: 'GET',
			})
		).then(data => {
			endpoints.changePassword = `${baseUrl}/accounts/${data.user_id}/password`;

			if (data.partner_code == '*') {
				dispatch(fetchPartners()).then(partners => {
					dispatch(this.setUserPartners(partners.content.map(partner => partner.partner_code)));
				});
			} else {
				dispatch(this.setUserPartners([data.partner_code]));
			}
		});

	setUserPartners = codes => {
		try {
			const currentUser = localStorage.getItem('current_user');

			if (currentUser) {
				const userDetails = JSON.parse(currentUser);
				userDetails.partner_codes = codes;
				localStorage.setItem('current_user', JSON.stringify(userDetails));
			}
		} catch (e) {}

		return {
			type: actionTypes.SET_USER_PARTNERS,
			codes,
		};
	};

	// refresh token
	requestTokenRefresh = () => ({
		type: actionTypes.REQUEST_TOKEN_REFRESH,
	});

	tokenRefreshed = data => ({
		type: actionTypes.TOKEN_REFRESHED,
		data,
	});

	failedToRefreshToken = error => {
		this.stopRefreshTokenTimer();
		let tokenExpirationDate;

		try {
			tokenExpirationDate = localStorage.getItem('expires') || 0;
		} catch (e) {
			bugsnag.notify('Token refresh failed, localStorage not available', {
				severity: 'warning',
				context: 'TokenRefresh',
			});
		}

		bugsnag.notify('Token refresh failed', {
			metaData: {
				debug: {
					expiresInMilliseconds: new Date(parseInt(tokenExpirationDate)) - Date.now(), //should always be positive num
					token: localStorage.getItem('token'),
					expiresDateFormatUnixMs: localStorage.getItem('expires'),
					refresh_token: localStorage.getItem('refresh_token'),
					errorStringify: JSON.stringify(error),
				},
			},
			severity: 'warning',
			context: 'TokenRefresh',
		});

		return {
			type: actionTypes.FAILED_TOKEN_REFRESH,
			error,
		};
	};

	refreshToken = () => dispatch => {
		let refreshToken;
		let tokenExpirationDate;
		try {
			refreshToken = localStorage.getItem('refresh_token');
			tokenExpirationDate = localStorage.getItem('expires') || 0;
		} catch (e) {
			return dispatch(this.localStorageUnavailable());
		}

		const expiresInMS = new Date(parseInt(tokenExpirationDate)) - Date.now();

		if (expiresInMS < 600000) {
			// If token is about to expire in 10 minutes, get the new one
			const currToken = localStorage.getItem('token');
			if (!currToken) {
				return null; // This should never happen!!
			}

			dispatch(
				axiosAjax({
					requestAction: this.requestTokenRefresh,
					successAction: this.tokenRefreshed,
					failureAction: this.failedToRefreshToken,
					url: endpoints.refreshToken,
					method: 'POST',
					params: { refresh_token: refreshToken },
					json: true,
				})
			).then(tokenData => {
				dispatch(this.setUserToken(tokenData));
			});
		}
		return null;
	};

	localStorageUnavailable = () => ({
		type: actionTypes.LOCAL_STORAGE_UNAVAILABLE,
	});

	setUserToken = tokenData => dispatch => {
		const expires = new Date().getTime() + tokenData.expires_in * 1000;

		try {
			localStorage.setItem('token', tokenData.access_token);
			localStorage.setItem('expires', expires);
			localStorage.setItem('refresh_token', tokenData.refresh_token);
			dispatch(this.clearLoginError());
		} catch (e) {
			dispatch(this.localStorageUnavailable());
		}
	};

	// revoke token
	requestTokenRevoke = () => ({
		type: actionTypes.REQUEST_TOKEN_REVOKE,
	});

	tokenRevoked = data => ({
		type: actionTypes.TOKEN_REVOKED,
		data,
	});

	failedToRevokeToken = error => ({
		type: actionTypes.FAILED_TOKEN_REVOKE,
		error,
	});

	revokeToken = () =>
		axiosAjax({
			requestAction: this.requestTokenRevoke,
			successAction: this.tokenRevoked,
			failureAction: this.failedToRevokeToken,
			url: endpoints.revokeToken,
			method: 'POST',
		});

	clearUser = () => {
		try {
			localStorage.removeItem('token');
			localStorage.removeItem('current_user');
			localStorage.removeItem('expires');
			localStorage.removeItem('refresh_token');
		} catch (e) {}

		this.stopRefreshTokenTimer();

		if (kickTimer) {
			clearTimeout(kickTimer);
		}

		return {
			type: actionTypes.CLEAR_USER,
		};
	};

	// kick timer
	kick = () => dispatch => {
		dispatch(this.logout());
	};

	logout = () => dispatch => {
		dispatch(this.revokeToken());
		dispatch(this.clearUser());
		dispatch({ type: actionTypes.CLEAR_PARTNERS });
	};

	setKickTimer = () => dispatch => {
		if (kickTimer) {
			clearTimeout(kickTimer);
		}

		kickTimer = setTimeout(() => {
			dispatch(this.kick());
		}, kickInterval);
	};

	getCurrentUserDetails = () => dispatch => {
		let token;

		try {
			token = localStorage.getItem('token');
		} catch (e) {
			dispatch(this.localStorageUnavailable());
			return;
		}

		if (token) {
			dispatch(this.sendRequestToGetCurrentUserDetails());

			dispatch(this.sendRequestToGetUserPrefs());
		}
	};

	triggerRefreshTokenTimer = () => dispatch => {
		if (!refreshTokenTimer) {
			refreshTokenTimer = setInterval(() => {
				dispatch(this.refreshToken());
			}, tokenRefreshIntervalMS);
		}
	};

	stopRefreshTokenTimer = () => {
		if (refreshTokenTimer) {
			clearInterval(refreshTokenTimer);
			refreshTokenTimer = null;
		}
	};

	sendSSOAuthCode = (authCode, callback) => dispatch => {
		dispatch(
			axiosAjax({
				requestAction: this.userStartedLogin,
				successAction: this.userLoggedIn,
				failureAction: this.userFailedLogin,
				url: `${endpoints.sso}/authenticate`,
				method: 'POST',
				params: { authCode },
				override: { contentType: 'application/x-www-form-urlencoded' },
				auth: false,
			})
		).then(() => {
			callback();
		});
	};

	clearLoginError = () => ({
		type: actionTypes.CLEAR_LOGIN_ERROR,
	});

	saveForcedFromPage = pathname => ({
		type: actionTypes.SAVE_FORCED_FROM_PAGE,
		pathname,
	});
}

export default new AuthActionCreators();
