import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { bugsnag } from 'gogo-sphere-shared-util';

import bindReduxBugsnagHandler from './bindReduxBugsnagHandler';
import bugsnagBreadcrumbs from './middleware/bugsnagBreadcrumbs';
import rootReducer from './reducers';

const middlewares = [thunkMiddleware, bugsnagBreadcrumbs];

let composeEnhancers = compose;

if (window.env === 'dev' || window.env === 'stage') {
	composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
		? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ name: 'GGS - SHARED - REDUX' })
		: compose;
}

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(...middlewares)));

export const mockStore = createStore(rootReducer);

bindReduxBugsnagHandler(bugsnag, store, 'REDUXSTORESHARED');

export default store;
