/*******************************/
/*********** SHARED ************/
/*******************************/
import keyMirror from 'keymirror';

export default keyMirror({
	/* LOCALIZATION */
	CHANGE_LOCALE: null,

	/* TOKEN and CONNECTIVITY*/
	USER_LOGGING_IN: null,
	USER_LOGGED_IN: null,
	USER_FAILED_LOGIN: null,

	REQUEST_USER_DETAILS: null,
	RECEIVED_USER_DETAILS: null,
	FAILED_USER_DETAILS: null,

	SET_USER_PARTNERS: null,

	REQUEST_USER_PREFS: null,
	RECEIVED_USER_PREFS: null,
	FAILED_USER_PREFS: null,

	REQUEST_PASSWORD_CHANGE: null,
	PASSWORD_CHANGED: null,
	FAILED_PASSWORD_CHANGE: null,

	START_SUBMIT_EMAIL_FOR_PASSWORD_RESET: null,
	SUBMITTED_EMAIL_FOR_PASSWORD_RESET: null,
	FAILED_SUBMIT_EMAIL_FOR_PASSWORD_RESET: null,
	RESET_FEEDBACK_MESSAGES: null,

	REQUEST_PASSWORD_RESET: null,
	PASSWORD_RESETTED: null,
	FAILED_PASSWORD_RESET: null,

	RESET_PASSWORD_STATUS: null,

	REQUEST_TOKEN_REFRESH: null,
	TOKEN_REFRESHED: null,
	FAILED_TOKEN_REFRESH: null,

	REQUEST_TOKEN_REVOKE: null,
	TOKEN_REVOKED: null,
	FAILED_TOKEN_REVOKE: null,

	START_PREF_UPDATE: null,
	PREF_UPDATED: null,
	FAILED_PREF_UPDATE: null,

	CLEAR_USER: null,

	SAVE_FORCED_FROM_PAGE: null,

	LOCAL_STORAGE_UNAVAILABLE: null,

	/* HEADER */
	TOGGLE_SIDEBAR: null,
	TOGGLE_APP_MENU: null,
	TOGGLE_PROFILE_MENU: null,
	DISABLE_AIRLINE_SWITCHER: null,
	CLOSE_ALL_MENUS: null,

	/* PARTNERS */
	REQUEST_PARTNER_DETAILS: null,
	RECEIVED_PARTNER_DETAILS: null,
	FAILED_PARTNER_DETAILS: null,

	/* USERS */

	/* PRODUCTS */

	/* GLOBAL ERRORS */
	UNAUTHORIZED_ERROR: null,
	TOKEN_EXPIRED: null,
	GLOBAL_ERROR: null,
	NETWORK_ERROR: null,
	CLEAR_LOGIN_ERROR: null,

	RESET_ALL_DATA: null,
	RECEIVED_BIA_ID: null,
	FAILED_BIA_ID: null,

	EXPORT_TABLE_REQUEST: null,
	EXPORT_TABLE_SUCCESS: null,
	EXPORT_TABLE_FAILURE: null,

	/* EMAIL SERVICE */
	REQUEST_EMAIL_SERVICE: null,
	ERROR_EMAIL_SERVICE: null,
	SUCCESS_EMAIL_SERVICE: null,
	RESET_EMAIL_SERVICE: null,

	/* PARTNERS do use this? (we have same in shared-admin ...) */
	SET_CURRENT_PARTNER: null,
	REQUEST_PARTNERS: null,
	RECEIVED_PARTNERS: null,
	RECEIVED_PARTNERS_ERROR: null,

	REQUEST_UPDATE_PARTNER: null,
	UPDATE_PARTNER_SUCCESS: null,
	UPDATE_PARTNER_ERROR: null,

	REQUEST_CREATE_PARTNER: null,
	CREATE_PARTNER_SUCCESS: null,
	CREATE_PARTNER_ERROR: null,

	CLEAR_PARTNERS: null,

	REQUEST_TABLEAU_INTERNAL_APPS: null,
	TABLEAU_INTERNAL_APPS_SUCCESS: null,
	TABLEAU_INTERNAL_APPS_ERROR: null,

	RECEIVED_AIRLINE_DETAILS: null,
});
