export default {
	excel: {
		http: {
			prod: 'http://edge.as.prod.ca.intelsat.com/commons/v1/converter/table/excel',
			stage: 'http://edge.as.stage.ca.intelsat.com/commons/v1/converter/table/excel',
			dev: 'http://edge.as.dev.ca.intelsat.com/commons/v1/converter/table/excel',
		},
		https: {
			prod: 'https://as-edge.api.ca.intelsat.com/commons/v1/converter/table/excel',
			stage: 'https://as-edge-stage.api.ca.intelsat.com/commons/v1/converter/table/excel',
			dev: 'https://as-edge-dev.api.ca.intelsat.com/commons/v1/converter/table/excel',
		},
	},
	pdf: {
		http: {
			prod: 'http://edge.as.prod.ca.intelsat.com/commons/v1/converter/table/pdf',
			stage: 'http://edge.as.stage.ca.intelsat.com/commons/v1/converter/table/pdf',
			dev: 'http://edge.as.dev.ca.intelsat.com/commons/v1/converter/table/pdf',
		},
		https: {
			prod: 'https://as-edge.api.ca.intelsat.com/commons/v1/converter/table/pdf',
			stage: 'https://as-edge-stage.api.ca.intelsat.com/commons/v1/converter/table/pdf',
			dev: 'https://as-edge-dev.api.ca.intelsat.com/commons/v1/converter/table/pdf',
		},
	},
	chart: {
		http: {
			prod: 'http://edge.as.prod.ca.intelsat.com/commons/v1/converter/chart/pdf',
			stage: 'http://edge.as.stage.ca.intelsat.com/commons/v1/converter/chart/pdf',
			dev: 'http://edge.as.dev.ca.intelsat.com/commons/v1/converter/chart/pdf',
		},
		https: {
			prod: 'https://as-edge.api.ca.intelsat.com/commons/v1/converter/chart/pdf',
			stage: 'https://as-edge-stage.api.ca.intelsat.com/commons/v1/converter/chart/pdf',
			dev: 'https://as-edge-dev.api.ca.intelsat.com/commons/v1/converter/chart/pdf',
		},
	},
};
