import actionTypes from '../constants/actionTypes';

const initialState = {};
let storagePrefs;

try {
	storagePrefs = JSON.parse(localStorage.getItem('userPrefs'));
} catch (err) {
	storagePrefs = null;
}

if (storagePrefs) {
	storagePrefs.map(item => {
		initialState[item.key] = item.value;
		return item;
	});
}

export default function auth(state = initialState, action) {
	switch (action.type) {
		case actionTypes.RECEIVED_USER_PREFS:
			const prefs = {};
			action.data.map(item => {
				prefs[item.key] = item.value;
				return item;
			});
			return prefs;
		default:
			return state;
	}
}
