import actionTypes from '../constants/actionTypes';
import { baseUrl } from '../constants/apiInfo';
import jqueryAjax from '../adapters/axiosAjax';

const endpoints = {
	submitEmailForPwdReset: `${baseUrl}/accounts/instructions`,
	resetPassword: `${baseUrl}/accounts/password`,
};

const passwordActionCreators = {
	// change password
	requestPasswordChange() {
		return {
			type: actionTypes.REQUEST_PASSWORD_CHANGE,
		};
	},

	passwordChanged(data) {
		return dispatch => {
			dispatch({
				type: actionTypes.PASSWORD_CHANGED,
				data,
			});
		};
	},

	failedToChangePassword(error) {
		return {
			type: actionTypes.FAILED_PASSWORD_CHANGE,
			error,
		};
	},

	changePassword(currentPassword, newPassword) {
		return jqueryAjax({
			requestAction: this.requestPasswordChange,
			successAction: this.passwordChanged,
			failureAction: this.failedToChangePassword,
			url: endpoints.resetPassword,
			method: 'PATCH',
			params: {
				old_password: currentPassword,
				new_password: newPassword,
			},
			json: true,
		});
	},

	// email for password reset
	startEmailSubmit() {
		return {
			type: actionTypes.START_SUBMIT_EMAIL_FOR_PASSWORD_RESET,
		};
	},

	emailSubmitted(data) {
		return {
			type: actionTypes.SUBMITTED_EMAIL_FOR_PASSWORD_RESET,
			data,
		};
	},

	failedToSubmitEmail(error) {
		return {
			type: actionTypes.FAILED_SUBMIT_EMAIL_FOR_PASSWORD_RESET,
			error,
		};
	},

	submitEmailForPasswordReset(email) {
		return jqueryAjax({
			requestAction: this.startEmailSubmit,
			successAction: this.emailSubmitted,
			failureAction: this.failedToSubmitEmail,
			url: endpoints.submitEmailForPwdReset,
			method: 'POST',
			params: { email },
			auth: false,
		});
	},

	resetPasswordStatus() {
		return {
			type: actionTypes.RESET_PASSWORD_STATUS,
		};
	},

	// password reset
	requestPasswordReset() {
		return {
			type: actionTypes.REQUEST_PASSWORD_RESET,
		};
	},

	passwordResetted(data) {
		return {
			type: actionTypes.PASSWORD_RESETTED,
			data,
		};
	},

	failedToResetPassword(error) {
		return {
			type: actionTypes.FAILED_PASSWORD_RESET,
			error,
		};
	},

	resetPassword(email, signature, newPassword) {
		return jqueryAjax({
			requestAction: this.requestPasswordReset,
			successAction: this.passwordResetted,
			failureAction: this.failedToResetPassword,
			url: endpoints.resetPassword,
			method: 'POST',
			params: {
				email,
				password: newPassword,
			},
			override: { headers: { 'X-Auth-Token-Pwd-Reset': signature } },
		});
	},
};

export default passwordActionCreators;
