import { objectToFormData } from 'gogo-sphere-shared-util';

import jqueryAjax from '../adapters/axiosAjax';
import { baseUrl } from '../constants/apiInfo';
import actionTypes from '../constants/actionTypes';

const requestEmailService = () => ({
	type: actionTypes.REQUEST_EMAIL_SERVICE,
});

const errorEmailService = error => ({
	type: actionTypes.ERROR_EMAIL_SERVICE,
	error,
});

const successEmailService = files => ({
	type: actionTypes.SUCCESS_EMAIL_SERVICE,
	files,
});

export const resetEmailService = () => ({
	type: actionTypes.RESET_EMAIL_SERVICE,
});

export const sendEmail = params =>
	jqueryAjax({
		requestAction: requestEmailService,
		successAction: successEmailService,
		failureAction: errorEmailService,
		url: `${baseUrl}/mail`,
		method: 'POST',
		override: {
			contentType: false,
			processData: false,
		},
		json: false,
		params: params && params ? objectToFormData(params) : undefined, // request body must be transformed to FormData
	});
