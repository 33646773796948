/* eslint-disable no-param-reassign */
export default function bindReduxBugsnagHandler(bugsnag, store, metaDataKey, apps = null) {
	//const oldHandler = bugsnag.beforeSend;
	/*bugsnag.beforeSend = report => {
		if (!apps || apps.includes(report.metaData.appShort)) {
			report.updateMetaData(metaDataKey, store.getState());
		}

		if (oldHandler) {
			oldHandler(report);
		}
	};*/

	bugsnag.config.beforeSend.push(report => {
		if (!apps || apps.includes(report.metaData.appShort)) {
			report.updateMetaData(metaDataKey, store.getState());
		}
	});
}
