import actionTypes from '../constants/actionTypes';

let initialState;

try {
	initialState = JSON.parse(localStorage.getItem('current_user'));
} catch (error) {
	initialState = {};
}

export default function userDetails(state = initialState, action) {
	switch (action.type) {
		case actionTypes.CLEAR_USER:
		case actionTypes.TOKEN_REVOKED:
			return null;
		case actionTypes.RECEIVED_USER_DETAILS:
			return action.data;
		case actionTypes.SET_USER_PARTNERS:
			return {
				...state,
				partner_codes: action.codes,
			};
		default:
			return state;
	}
}
