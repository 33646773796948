import { saveBinary } from 'gogo-sphere-shared-util';

import actionTypes from '../constants/actionTypes';
import { exportUrls } from '../constants/apiInfo';
import jqueryAjax from '../adapters/axiosAjax';

const exportRequest = () => ({
	type: actionTypes.EXPORT_TABLE_REQUEST,
});

const exportSuccess = type => ({
	type: actionTypes.EXPORT_TABLE_SUCCESS,
	response: type,
});

const exportFailure = error => ({
	type: actionTypes.EXPORT_TABLE_FAILURE,
	error,
});

export default function exportData(params, type, name) {
	return dispatch =>
		dispatch(
			jqueryAjax({
				requestAction: exportRequest,
				failureAction: exportFailure,
				url: exportUrls[type],
				method: 'POST',
				json: true,
				override: {
					responseType: 'arraybuffer',
				},
				params,
			})
		)
			.then(data => {
				try {
					saveBinary(
						data,
						`${name || 'report'}.${type === 'excel' ? 'xlsx' : 'pdf'}`,
						type === 'excel'
							? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
							: 'application/pdf'
					);
				} catch (e) {
					dispatch(exportFailure(e));
				}
				dispatch(exportSuccess(type));
			})
			.catch(e => {
				dispatch(exportFailure(e));
			});
}
