export default {
	http: {
		prod: 'http://edge.as.prod.ca.intelsat.com/tbl',
		stage: 'http://edge.as.stage.ca.intelsat.com/tbl',
		dev: 'http://edge.as.dev.ca.intelsat.com/tbl',
	},
	https: {
		prod: 'https://as-edge.api.ca.intelsat.com/tbl',
		stage: 'https://as-edge-stage.api.ca.intelsat.com/tbl',
		dev: 'https://as-edge-dev.api.ca.intelsat.com/tbl',
	},
};
