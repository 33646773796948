import { bugsnag } from 'gogo-sphere-shared-util';

export default function bugsnagBreadcrumbs() {
	return next => action => {
		if (action && action.type) {
			const { type, ...data } = action;
			const breadcrumbData = {};

			Object.keys(data).forEach(key => {
				const itemType = typeof data[key];

				if (data[key] === null) {
					breadcrumbData[key] = 'null';
				} else if (itemType == 'object') {
					const { token, access_token, refresh_token, ...okData } = data[key];
					breadcrumbData[key] = JSON.stringify(okData);
				} else if (itemType == 'function') {
					breadcrumbData[key] = itemType;
				} else {
					breadcrumbData[key] = data[key];
				}
			});

			bugsnag.leaveBreadcrumb(type, breadcrumbData);
		}

		return next(action);
	};
}
