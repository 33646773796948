import { hasPermission } from 'gogo-sphere-shared-util';

import userRoles from '../constants/userRoles';

let lastUser;
let lastApps;
let lastPartner;
let lastResult;

const makePageAllowed = permissions =>
	function pageAllowed(page) {
		if (page.permission) {
			return typeof page.permission === 'function'
				? page.permission(permissions)
				: hasPermission(permissions, page.permission);
		} else if (page.pages) {
			return page.pages.some(pageAllowed);
		}

		return false;
	};

export default function(user, apps, partner) {
	if (user === lastUser && apps === lastApps && partner === lastPartner) {
		return lastResult;
	}

	lastUser = user;
	lastApps = apps;
	lastPartner = partner;

	if (!user) {
		lastResult = [];
	} else {
		const pageAllowed = makePageAllowed(user.permissions);

		lastResult = apps.filter(application => {
			// return if app has exceptional restrictions
			if (application.exceptionalRestriction === true && window.env !== 'dev') {
				return false;
			} else if (typeof application.exceptionalRestriction == 'function') {
				if (application.exceptionalRestriction(user, partner)) {
					return false;
				}
			}

			// if there's airline restrictions, check those first and return false if not met
			if (application.airlines && application.airlines != '*' && !application.airlines.includes(partner)) {
				return false;
			}

			if (
				application.path === 'admin' &&
				(!Array.isArray(user.roles) ||
					(user.roles.indexOf(userRoles.GOGO_ADMIN) === -1 &&
						user.roles.indexOf(userRoles.GOGO_SUPER_ADMIN) === -1 &&
						user.roles.indexOf(userRoles.PARTNER_ADMIN) === -1))
			) {
				return false;
			}

			let isAllowed = false;

			if (application.permission) {
				isAllowed = hasPermission(user.permissions, application.permission);
			} else if (application.pages && application.pages.length) {
				isAllowed = application.pages.some(pageAllowed);
			} else {
				isAllowed = true;
			}

			if (!isAllowed) {
				// is extraPerms are defined for app and user doesn't have any, don't allow him to have access to the app
				if (application.extraPermissions && application.extraPermissions.length > 0) {
					if (user.permissions) {
						for (let i = 0, len = user.permissions.length; i < len; i++) {
							if (
								application.extraPermissions.indexOf(user.permissions[i].target) >= 0 ||
								application.extraPermissions.indexOf(user.permissions[i].domain) >= 0
							) {
								isAllowed = true;
								break;
							}
						}
					}
				}
			}

			return isAllowed;
		});
	}

	return lastResult;
}
