import actionTypes from '../constants/actionTypes';
import { baseUrl } from '../constants/apiInfo';
import axiosAjax from '../adapters/axiosAjax';

const requestPartnerDetails = () => ({
	type: actionTypes.REQUEST_PARTNER_DETAILS,
});

const receivedPartnerDetails = data => ({
	type: actionTypes.RECEIVED_PARTNER_DETAILS,
	data,
});

const failedPartnerDetails = error => ({
	type: actionTypes.FAILED_PARTNER_DETAILS,
	error,
});

const fetchPartnerDetails = partner => {
	if (partner) {
		return dispatch =>
			dispatch(
				axiosAjax({
					requestAction: requestPartnerDetails,
					successAction: receivedPartnerDetails,
					failureAction: failedPartnerDetails,
					url: `${baseUrl}/partners?partner_code=${partner}`,
					method: 'GET',
				})
			).then(data => {
				localStorage.setItem('partnerCode', data['partner_code']);
				localStorage.setItem('partnerDetails', JSON.stringify(data));
			});
	}
	return {
		type: 'AIRLINE_UNAVAILABLE',
	};
};

export default fetchPartnerDetails;
