import { combineReducers } from 'redux';
import { reducer as notifications } from 'react-notification-system-redux';

import currentPartner from './reducers/currentPartner';
import forcedFromPage from './reducers/forcedFromPage';
import isTokenExpired from './reducers/isTokenExpired';
import language from './reducers/language';
import loginErrorMessage from './reducers/loginErrorMessage';
import loginStatus from './reducers/loginStatus';
import menuControl from './reducers/menuControl';
import notificationSystem from './reducers/notificationSystem';
import passwordStatus from './reducers/passwordStatus';
import userDetails from './reducers/userDetails';
import userPrefs from './reducers/userPrefs';
import lastAction from './reducers/lastAction';
import partners from './reducers/partnersReducer';
import { tableauApps, tableauReports, gotTableauApps, BIATableauAppId } from './reducers/tableauApps';
import tableauInternalApps from './reducers/tableauInternalApps';

export default combineReducers({
	menuControl,
	isTokenExpired,
	loginErrorMessage,
	notificationSystem,
	loginStatus,
	currentPartner,
	userPrefs,
	userDetails,
	language,
	passwordStatus,
	notifications,
	tableauApps,
	tableauInternalApps,
	partners,
	tableauReports,
	BIATableauAppId,
	gotTableauApps,
	forcedFromPage,
	lastAction,
});
